


































































































import './styles/ProjectTasksHeader.scss';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { IProject } from '@store/modules/project/Interfaces';
import { ITask, ITaskGroup } from "@store/modules/project-tasks/interfaces/Interfaces";
import { userNamespace } from "@store/modules/user";
import { IUserState } from "@store/modules/user/Interfaces";

const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');

@Component({
    name: 'ProjectTasksHeader',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        ButtonFavorite: () => import('@components/buttons/ButtonFavorite.vue'),
        BaseSelect: () => import('@components/BaseSelect'),
        PopupFiltersTasks: () => import('@components/popups/PopupFiltersTasks.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        PopupTaskEdit: () => import('@components/popups/PopupTaskEdit.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
        PopupAddTask: () => import('@components/popups/PopupAddTask.vue'),
    },
})
export default class ProjectTasksHeader extends Vue {
    $refs!: {
        popupReplaceDocuments: HTMLFormElement,
        filterPopup: HTMLFormElement,
        popupTaskEdit: HTMLFormElement,
        filtersPreview: HTMLFormElement,
    }

    @Prop({}) activeTaskGroups!: ITaskGroup[];
    @Prop({}) selectedTasks!: ITask[];
    @Prop({ default: 2500 }) showSuccessDeletePopupDuration!: number;
    @Prop({ default: false }) deleteTaskConfirmBtnDisabled!: boolean;
    @Prop({default: false}) popupSuccessDeleteOpen: boolean = false;

    emitAddingButtonClick() {
        this.$emit('adding-button-click');
    }

    @userNamespace.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @userNamespace.Getter('userData') userData!: IUserState;
    @NSProject.Getter('projectData') projectData!: IProject;
    @NSProjectTasks.Mutation(ProjectTasksMutations.M_CHANGE_GROUPS_FILTER)
    changeGroupsFilter!: (filterValue: 'all' | 'new' | 'assign' | 'execute' | 'done') => void;

    confirmRemovePopupShow: boolean = false;
    copyTaskPopupToggler: boolean = false;
    accessDeniedPopupShow: boolean = false;
    accessDeniedText: string = '';
    currentGroup: 'all' | 'new' | 'assign' | 'execute' | 'done' = 'all';

    views: any[] = [
        {
            label: 'Все задачи',
            value: 'all',
        },{
            label: 'Новые',
            value: 'new',
        },{
            label: 'Поручил',
            value: 'assign',
        },{
            label: 'Исполняю',
            value: 'execute',
        },{
            label: 'Завершенные',
            value: 'done',
        },
    ];
    filters = [
        { label: 'По автору' },
        { label: 'По дате' },
        { label: 'По статусу' },
    ];

    @Watch('selectedTasks')
    onChangeSelectedTasks() {
        if (this.selectedTasks.length > 0 && (this.confirmRemovePopupShow || this.accessDeniedPopupShow)) {
            this.checkBeforeDelete();
        }
    }

    mounted() {
        this.resetFilters();
    }

    get actionsForSelect() {
        const output = [] as {}[];
        if (this.selectedTasks.length) {
            output.push({
                action: 'edit',
                label: 'Редактировать',
            })
            output.push({
                action: 'delete',
                label: 'Удалить',
            })
            output.push({
                action: 'copy',
                label: 'Копировать',
            })
        }
        return output;
    }

    get multipleTasks() {
        return this.selectedTasks.length > 1;
    }

    get tasksString() {
        return `"${this.selectedTasks.map(task => task.title).join('", "')}"`
    }

    get deletionConfirmationText() {
        return `Вы уверены, что хотите удалить задач${this.multipleTasks ? 'и' : 'у'} ${this.tasksString}?`
    }

    get successfulDeletionTitle() {
        return this.multipleTasks ? 'Задачи удалены' : 'Задача удалена'
    }

    get successfulDeletionText() {
        return `Задач${this.multipleTasks ? 'и' : 'а'} ${this.tasksString} успешно удален${this.multipleTasks ? 'ы' : 'а'}`
    }

    emitChangeGroupFilter(event) {
        if (this.currentGroup !== event.value) {
            this.currentGroup = event.value;
            this.changeGroupsFilter(this.currentGroup);
            this.$emit('change-groups-filter');
        }
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    resetFilters() {
        if (this.$refs.filterPopup) {
            this.$refs.filterPopup.cancelFilters();
        }
    }

    onSelectAction(e) {
        switch (e.action) {
            case 'delete':
                this.checkBeforeDelete();
                break;
            case 'edit':
                this.checkBeforeEdit();
                break;
            case 'copy':
                this.checkBeforeCopy();
                break;
            default:
                return 0;
        }
    }

    openEditPopup() {
        this.$refs.popupTaskEdit.openPopup();
    }

    onConfirmDeleteTacks() {
        this.$emit('confirm-delete-task');
    }

    checkBeforeDelete() {
        let canBeDelete: boolean = true;
        if (this.selectedTasks.length === 1) {
            const stateName = this.selectedTasks[0].state.name;
            if (stateName !== 'Новое' && stateName !== 'В работе') {
                canBeDelete = false;
                this.accessDeniedText = `Вы не можете удалить задачу "${this.selectedTasks[0].title}"
                т.к. удаление допустимо только для задач в статусах "Новое" и "В работе".`;
            }
        } else {
            const nonDeletableTasks: string[] = [];
            this.selectedTasks.forEach((task) => {
                if (task.state.name !== 'Новое') {
                    nonDeletableTasks.push(task.title)
                }
            })
            if (nonDeletableTasks.length) {
                canBeDelete = false;
                const nonDeletableTasksString = `"${nonDeletableTasks.join('", "')}"`
                this.accessDeniedText = `Вы не можете удалить задач${nonDeletableTasks.length > 1 ? 'и' : 'у'}
                ${nonDeletableTasksString} т.к. множественное удаление допустимо только для задач в статусе "Новое".`;
            }
        }
        if (canBeDelete) {
            this.switchPopupToggler('accessDeniedPopupShow', false);
            this.switchPopupToggler('confirmRemovePopupShow', true);
        } else {
            this.switchPopupToggler('confirmRemovePopupShow', false);
            this.switchPopupToggler('accessDeniedPopupShow', true);
        }
    }

    checkBeforeEdit() {
        let canBeEdited:boolean = true;
        const nonEditableTasks: string[] = [];
        this.selectedTasks.forEach((task) => {
            if (task.state.name === 'Выполнено' || task.state.name === 'На проверке') {
                nonEditableTasks.push(task.title)
            }
        })
        if (nonEditableTasks.length) {
            canBeEdited = false;
            const nonDeletableTasksString = `"${nonEditableTasks.join('", "')}"`
            this.accessDeniedText = `Вы не можете редактировать задач${nonEditableTasks.length > 1 ? 'и' : 'у'}
            ${nonDeletableTasksString} т.к. редактирование недоступно для задач в статусах "На проверке" и "Выполнено".`;
        }
        if (canBeEdited) {
            this.openEditPopup();
        } else {
            this.switchPopupToggler('accessDeniedPopupShow', true);
        }
    }

    checkBeforeCopy() {
        let canBeCopied = true;
        if (this.selectedTasks.length > 1) {
            canBeCopied = false;
            this.accessDeniedText = `Вы не можете скопировать задачи т.к. копирование нескольких задач невозможно`;
        }
        if (canBeCopied) {
            this.switchPopupToggler('copyTaskPopupToggler', true);
        } else {
            this.switchPopupToggler('accessDeniedPopupShow', true);
        }
    }

    switchPopupToggler(togglerKey: string, toggleState: boolean) {
        this[togglerKey] = toggleState;
    }

    setFiltersPreview(filtersData) {
        this.$refs.filtersPreview.setFiltersData(filtersData);
    }

    resetFiltersByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    resetFiltersDateByPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }
}
